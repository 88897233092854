<template>
	<div>
		<el-form :inline="true" ref="form" :model="form" label-width="120px">
			<el-form-item label="阀门状态" prop="valve">
				<el-select v-model="form.valve" placeholder="请选择阀门状态">
					<el-option label="在线" value="在线"></el-option>
					<el-option label="离线" value="离线"></el-option>
					<el-option label="全部" value="全部"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">搜索</el-button>
			</el-form-item>
			<el-form-item>
				<slot name="ms"></slot>
			</el-form-item>
		</el-form>
		<!-- <div class="statistics d-flex">
			<div class="supplyWaterTemperature">
				平均供水温度 {{gswd}}℃
			</div>
			<div class="supplyWaterTemperature">
				平均回水温度 {{hswd}}℃
			</div>
			<div class="supplyWaterTemperature">
				平均室内温度 --℃
			</div>
		</div> -->
		<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="全部" name="all"></el-tab-pane>
		</el-tabs> -->
	</div>
</template>

<script>
	import { getPsSelectValue, getTdeSelectValue, getTgSelectValue } from "../assets/js/api";
	export default {
		name: "search",
		props: {
			form: {
				type: Object,
				default: () => [{}]
			},
			onSubmit: {
				type: Function,
				default: () => {}
			},
			atPresent: {
				type: Function,
				default: () => {}
			},
			biaoshi: {
				type: String,
				default: "fjbh"
			},
			type: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				activeName: 'all',
				// gswd: '--',
				// hswd: '--',
				xqmcArr: [],
				hrzmcArr: [],
				fgsArr: [],
				dyhArr: [],
				fjbhArr: [],
			};
		},
		created() {
			// this.averageTemperature();
			switch (this.type) {
				case 'ps':
					this.psOptions();
					break;
				case 'tge':
					this.tgeOptions();
					break;
				case 'tg':
					this.tgOptions();
					break;
				default:
					break;
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
			psOptions() {
				getPsSelectValue({
					type: 'xqmc'
				}).then(res => {
					this.xqmcArr = res.data;
					console.log(this.xqmcArr)
				})
				getPsSelectValue({
					type: 'hrzmc'
				}).then(res => {
					this.hrzmcArr = res.data;
				})
				getPsSelectValue({
					type: 'fgs'
				}).then(res => {

					this.fgsArr = res.data;
				})
				getPsSelectValue({
					type: 'fjbh'
				}).then(res => {
					this.fjbhArr = res.data;
				})
			},
			tgeOptions() {
				getTdeSelectValue({
					type: 'xqmc'
				}).then(res => {
					this.xqmcArr = res.data;
				})
				getTdeSelectValue({
					type: 'hrzmc'
				}).then(res => {
					this.hrzmcArr = res.data;
				})
				getTdeSelectValue({
					type: 'fgs'
				}).then(res => {
					this.fgsArr = res.data;
				})
				getTdeSelectValue({
					type: 'dyh'
				}).then(res => {
					this.dyhArr = res.data;
				})
			},
			tgOptions() {
				getTgSelectValue({
					type: 'xqmc'
				}).then(res => {
					this.xqmcArr = res.data;
				})
				getTgSelectValue({
					type: 'hrzmc'
				}).then(res => {
					this.hrzmcArr = res.data;
				})
				getTgSelectValue({
					type: 'fgs'
				}).then(res => {
					this.fgsArr = res.data;
				})
				getTgSelectValue({
					type: 'fjbh'
				}).then(res => {
					this.fjbhArr = res.data;
				})
			},
			// averageTemperature() {
			// 	// 平均供水温度
			// 	// 平均回水温度
			// 	// 平均室内温度
			// 	this.atPresent().then(res => {
			// 		this.gswd = res.data.data.gswd;
			// 		this.hswd = res.data.data.hswd;
			// 	})
			// }
		},
	}
</script>

<style scoped>
	.el-form{
		background-color: #051A3B;
		padding: 15px;
		margin-bottom: 20px;
	}
	.el-form-item{
		margin-bottom: 0;
	}
	/deep/.el-form-item__label{
		text-align: left;
		font-size: 16px;
		color: #E5F6FF;
	}
	/deep/.el-input__inner{
		background-color: #002040;
		border: 1px solid #0062C2;
	}
	.statistics {
		justify-content: space-between;
		padding-left: 20%;
		padding-right: 20%;
		line-height: 40px;
		color: #E6A23C;
	}
	/deep/.el-button--primary{
		background: linear-gradient(to right, #0066E3, #009BF5);
		border: 0;
	}
</style>
